import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import blog_image from "../images/blog-blocks-wallpaper-1591056-2.jpg";
import { Link, graphql } from "gatsby";
import BlogHeader from "../components/blog-header";
import moment from "moment";
import { startCase } from "lodash";
import Last10Js from "../components/last-10-js";
import Last10Elixir from "../components/last-10-elixir";
import UsefulLinks from "../components/useful-links";
import CategoriesList from "../components/available-cats-list";
import ArchiveLink from "../components/archive-link";
import {
  FaTwitter,
  FaFacebook,
  FaEnvelope,
  FaPinterest,
  FaLinkedin,
} from "react-icons/fa";
import { ShareButtonRoundSquare, ShareBlockStandard } from "react-custom-share";
import BlogLayout from '../components/blog-layout';

const SocialBlock = ({ siteUrl }) => {
  const shareBlockProps = {
    url: siteUrl,
    button: ShareButtonRoundSquare,
    buttons: [
      { network: "Twitter", icon: FaTwitter },
      { network: "Facebook", icon: FaFacebook },
      { network: "Email", icon: FaEnvelope },
      {
        network: "Pinterest",
        icon: FaPinterest,
        media: "https://localhost:8000/image-to-share.jpg",
      },
      { network: "Linkedin", icon: FaLinkedin },
    ],
    text: `Just putting this out there - distortedthinking.agency - `,
    longtext: `Just putting this out there - distortedthinking.agency - `,
  };

  return <ShareBlockStandard {...shareBlockProps} />;
};

const StyledLink = ({ to, children }) => {
  return (
    <Link to={to} className="text-red-700 text-lg font-title">
      {children}
    </Link>
  );
};

const PageLinks = ({ next, prev }) => {
  return (
    <div className="w-full flex justify-between">
      {!prev && <span>&nbsp;</span>}
      {prev && (
        <StyledLink to={`${prev.frontmatter.path}`}>
          « {prev.frontmatter.title}
        </StyledLink>
      )}
      {!next && <span>&nbsp;</span>}
      {next && (
        <StyledLink to={`${next.frontmatter.path}`}>
          {next.frontmatter.title} »
        </StyledLink>
      )}
    </div>
  );
};

const Post = ({ date, title, author, html, siteUrl }) => {
  const dateFormatted = moment.utc(date).format("MMMM DD, YYYY");
  return (
    <div className="bg-white border p-6 mb-4">
      <header>
        <h1 className="text-3xl font-title text-red-700">{title}</h1>
        <p className="mb-0 border-b text-gray-800 text-sm pb-2 mb-0">
          Posted on {dateFormatted} by {startCase(author)}
        </p>
        <div className="border-b text-gray-800 flex md:justify-center pb-1">
          <SocialBlock siteUrl={siteUrl} />
        </div>
      </header>
      <div className="markdown-body">
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
    </div>
  );
};

const BlogPost = ({ data, pageContext: { next, prev } }) => {
  const {
    post: {
      frontmatter: { date, title, author, path },
      html,
    },
  } = data;
  const url = `${data.site.siteMetadata.siteUrl}${path}`;
  return (
    <Layout headerClass="bg-white relative" bodyClass="container mx-auto md:px-10 lg:px-16">
      <SEO title={title} />
      <BlogLayout title={`Blog`} image={blog_image}>
        <div className="container md:mt-20 font-title self-start"></div>
        <BlogHeader />
        <div className="w-full flex flex-wrap pt-4 pb-4 bg-gray-50">
          <div className="w-full md:w-3/4 md:px-4">
            <>
              <Post
                date={date}
                title={title}
                author={author}
                html={html}
                siteUrl={url}
              ></Post>
              <PageLinks next={next} prev={prev}></PageLinks>
            </>
          </div>
          <div className="w-full md:w-1/4 px-4">
            <div className="mb-6">
              <Last10Elixir />
            </div>
            <div className="mb-6">
              <Last10Js />
            </div>
            <div className="mb-6">
              <UsefulLinks />
            </div>
            <div className="mb-6">
              <CategoriesList />
            </div>
            <div className="mb-6">
              <ArchiveLink />
            </div>
          </div>
        </div>
      </BlogLayout>
    </Layout>
  );
};

export const query = graphql`
  query($id: String) {
    post: markdownRemark(id: { eq: $id }) {
      frontmatter {
        author
        title
        path
        date
      }
      html
    }
    site: site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default BlogPost;
